import { render, staticRenderFns } from "./liveliveAnswerRecord.vue?vue&type=template&id=7c967f0d&scoped=true&"
import script from "./liveliveAnswerRecord.vue?vue&type=script&lang=js&"
export * from "./liveliveAnswerRecord.vue?vue&type=script&lang=js&"
import style0 from "./liveliveAnswerRecord.vue?vue&type=style&index=0&id=7c967f0d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c967f0d",
  null
  
)

export default component.exports