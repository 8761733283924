<template>
    <div style=" margin: -15px -15px;">
      <a-spin :spinning="spinning">
      <div class="titleTop">
        {{titleTop}}
      </div>
      <div class="liveMeet-top">
  <!--      <div class="liveMeet-top-input">-->
  <!--        <div style="width: 100px;text-align: right">直播会议:</div>-->
  <!--        <a-select-->
  <!--            v-model="topValue.meeting"-->
  <!--            placeholder="请选择直播会议"-->
  <!--            style="width: 200px;margin-left: 10px"-->
  <!--            @change="selectAddChange">-->
  <!--          <a-select-option-->
  <!--              v-for="(live,index) in 5"-->
  <!--              :value="'直播间' + index">-->
  <!--            直播间{{index}}-->
  <!--          </a-select-option>-->
  <!--        </a-select>-->
  <!--      </div>-->
        <div class="liveMeet-top-input">
          <div style="width: 100px;text-align: right">直播间:</div>
          <a-select
              v-model="topValue.room"
              placeholder="请选择"
              style="width: 200px;margin-left: 10px"
              @change="selectAddChange">
            <a-select-option
                v-for="(live,index) in roomListVal"
                :value="live.title">
              {{live.title}}
            </a-select-option>
          </a-select>
        </div>
        <div class="liveMeet-top-input">
<!--          <div style="width: 100px;text-align: right">选项:</div>-->
<!--          <a-select-->
<!--              v-model="topValue.options"-->
<!--              placeholder="请选择"-->
<!--              style="width: 200px;margin-left: 10px"-->
<!--              @change="selectAddChange">-->
<!--            <a-select-option-->
<!--                v-for="(live,index) in answersVal"-->
<!--                :value="live">-->
<!--              {{live}}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
          <a-checkbox v-model="checkAllTrue" @change="onChange">
            全部正确
          </a-checkbox>
        </div>
        <div class="liveMeet-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
<!--        <div class="liveMeet-top-input">-->
<!--          <a-button-->
<!--              @click="exportBtn"-->
<!--              style="margin-left: 10px">-->
<!--            <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />-->
<!--            批量导出-->
<!--          </a-button>-->
<!--        </div>-->
      </div>
      <div class="activity-table">
        <!--      :rowKey="record=>record.id"    -->
        <a-table
            :rowKey="record=>record.id"
            :columns="columnsLive"
            :data-source="dataLive"
            :pagination="false">
           <span slot="isAllRight" slot-scope="text, record">
             {{record.isAllRight ? '是':'否'}}
           </span>
          <span slot="receivingAddress" slot-scope="text, record">
             {{record.receivingAddress ? record.receivingAddress:'未填写收货地址'}}
           </span>
           <span slot="system" slot-scope="text, record">
              <a-popconfirm
                  v-if="1"
                  :title="record.isWinning ? '是否取消中将名额？':'标记为已中奖?'"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirmWin(record.id,record.isWinning ? 0:1)"
              >
                <a v-if="record.isAllRight" :style="{color: record.isWinning ? '#f82c2c':'#45a5e6'}">
                  {{record.isWinning ? '已中奖':'标记中奖'}}
                </a>
              </a-popconfirm>

<!--             <span style="color: #45A5E6;" v-else>标记中奖</span>-->
           </span>
        </a-table>
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 15px">
            <a-pagination
                :current="pageNo"
                @change="paginationBtn"
                :total="tableCount" />
          </div>
        </div>
      </div>
        <div style="height: 80px"></div>
      <div style="width:100%;height:50px;position: fixed;bottom: 0;background-color: #f0f2f5;border-bottom: 1px solid #fff">
        <div style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
          <a-button @click="returnBtn">返回上一层</a-button>
        </div>
      </div>
      </a-spin>
    </div>
  </template>
  <script>
  import {Download_answers_exal, getAnswer, getAnswerRecord, putAnswerRecord} from "../../service/liveMeet_api";
  import axios from "axios";

  const columnsLive = [
    {
      title: '姓名',
      dataIndex: 'memberName',
      key: 'memberName',
      width:'7%'
    },
    {
      title: '医院',
      dataIndex: 'memberHospital',
      key: 'memberHospital',
      width:'15%'
    },
    {
      title: '科室',
      dataIndex: 'memberHospitalOffices',
      key: 'memberHospitalOffices',
      ellipsis: true,
      width:'10%',
      scopedSlots: { customRender: 'memberHospitalOffices' },
    },
    {
      title: '职称',
      dataIndex: 'memberJobTitle',
      key: 'memberJobTitle',
      ellipsis: true,
      width:'10%',
      scopedSlots: { customRender: 'memberJobTitle' },
    },
    {
      title: '收货地址',
      dataIndex: 'receivingAddress',
      key: 'receivingAddress',
      scopedSlots: { customRender: 'receivingAddress' },
      ellipsis: true,
    },
    {
      title: '答题时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
      width:'160px',
      scopedSlots: { customRender: 'createdTime' },
    },
    {
      title: '答题是否正确',
      dataIndex: 'isAllRight',
      key: 'isAllRight',
      // align:'center',
      scopedSlots: { customRender: 'isAllRight' },
    },
    {
      title: '操作',
      key: 'system',
      scopedSlots: { customRender: 'system' },
    },
  ];
  const dataLive = [
  ];
  export default {
    data(){
      return{
        spinning:false,
        titleTop:'',
        roomListVal:[],
        answersVal:[],
        columnsLive,
        dataLive,
        pageNo:1,
        tableCount:0,
        topValue:{
          meeting:undefined,
          room:undefined,
          options:undefined,
        },
        checkAllTrue:false,
      }
    },
    created() {
      this.getTableList()
      this.getListTitle()
    },
    methods:{
      //标题
      async getListTitle() {
        const response = await getAnswer()
        if (response.code === 0) {
          let form = response.data.rows
          for (let i=0;i<form.length;i++){
            if(form[i].id === (this.$route.query.id)*1){
              this.titleTop = form[i].title + '--' + form[i].answers
              this.roomListVal = form[i].liveMeet
              this.answersVal = form[i].answers
            }
          }
        } else {
          this.$message.warning("出错，请联系管理员", response.message)
        }
      },
      async getTableList(answer,liveStreamTitle,isAllRight) {
        this.spinning = true;
        let data = {
          questionId:this.$route.query.id,
          answer:answer,
          liveStreamTitle:liveStreamTitle,
          isAllRight:isAllRight,
          page_no:this.pageNo
        }
        const response = await getAnswerRecord(data)
        if(response.code === 0){
          this.dataLive = response.data.rows
          this.tableCount = response.data.count
        }else {
          this.$message.warning("出错，请联系管理员",response.message)
        }
        this.spinning = false
      },
      resetBtn(){
        let form = this.topValue;
        //清空
        form.meeting = undefined;
        form.room = undefined;
        form.options = undefined;
        this.checkAllTrue = false;
        this.pageNo = 1;
        this.getTableList()
      },
      seachBtn(){
        this.pageNo = 1;
        this.getTableList(this.topValue.options,this.topValue.room,this.checkAllTrue ? 1:0)
      },
      selectAddChange(val){
        this.pageNo = 1;
        this.getTableList(this.topValue.options, this.topValue.room, this.checkAllTrue ? 1 : 0)
      },
      onChange(){
        this.pageNo = 1;
        this.getTableList(this.topValue.options, this.topValue.room, this.checkAllTrue ? 1 : 0)
      },
      async confirmWin(id,isWinning) {
        const response = await putAnswerRecord(id,isWinning)
        if (response.code === 200) {
          await this.getTableList(this.topValue.options, this.topValue.room, this.checkAllTrue ? 1 : 0)
        } else {
          this.$message.warning(response.message)
        }
      },
      async exportBtn() {
        this.spinning = true
        let data = {
          questionId: this.$route.query.id,
          answer: this.topValue.options,
          liveStreamTitle: this.topValue.room,
          ExelName:"答题记录",
        }
        const response = await Download_answers_exal(data)
        this.spinning = false
      },
      returnBtn(){
        window.history.back()
      },
      paginationBtn(page_no,pageSize){
        this.pageNo = page_no;
        this.getTableList(this.topValue.options, this.topValue.room, this.checkAllTrue ? 1 : 0)
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  .titleTop{
    height: 50px;
    border-bottom: 10px solid #f5f5f5;
    line-height: 40px;
    font-weight: bold;
    color: black;
    padding-left: 15px;
  }
  .liveMeet-top{
    width: 100%;
    height: 85px;
    border-bottom: 15px solid #f0f2f5;
    display: flex;
    justify-content: space-between;
    .liveMeet-top-input{
      display: flex;
      justify-content: center;
      width: 25%;
      height: 50px;
      line-height: 30px;
      padding-top: 20px;
      .input{
        width: 70%;
        margin-left: 10px;
      }
    }
  }
  .activity-table{
    width: 100%;
    min-height: 600px;
  }
  </style>
